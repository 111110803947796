import React, { ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import InputText from '../../atoms/input-text/input-text';
import { FormErrors, getElementError } from '@hooks/use-form-validation';
import Checkbox from '@components/atoms/checkbox/checkbox';

interface PersonalInformationData {
  first_name?: string;
  last_name?: string;
  email?: string;
  email_confirmation?: string;
  phone?: string;
  accepted_privacy_policy?: boolean;
}

type PersonalInformationFields = (keyof PersonalInformationData)[];

interface Props {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  values: PersonalInformationData;
  errors?: FormErrors<PersonalInformationData>;
  hasSubmitted?: boolean;
  includeEmail?: boolean;
  includeEmailConfirmation?: boolean;
  requiredFields?: PersonalInformationFields;
  includePrivacyPolicy?: boolean;
}

const defaultRequiredFields: PersonalInformationFields = [
  'first_name',
  'last_name',
  'email',
  'email_confirmation',
  'accepted_privacy_policy',
];

export const messages = defineMessages({
  labelFirstName: {
    id: '46hFFm',
    description: 'Form label for first name',
    defaultMessage: 'First Name',
  },
  labelLastName: {
    id: '0pfqXu',
    description: 'Form label for last name',
    defaultMessage: 'Last Name',
  },
  labelEmail: {
    id: 'fZG/J7',
    description: 'Form label for email address',
    defaultMessage: 'Email',
  },
  labelEmailConfirmation: {
    id: '3Pf5Cd',
    description: 'Form label for email address confirmation',
    defaultMessage: 'Email Confirmation',
  },
  labelPhone: {
    id: '1NINSs',
    description: 'Form label for phone number',
    defaultMessage: 'Phone',
  },
  labelPrivacyPolicy: {
    id: 'LSGOxY',
    defaultMessage: 'I have read and understand the <a>privacy policy.</a>',
    description: 'Checkbox label for privacy policy, shown on sign-up forms.',
  },
});

export const PersonalInformation = ({
  values,
  handleChange,
  hasSubmitted,
  errors,
  includeEmail = true,
  includeEmailConfirmation = true,
  includePrivacyPolicy = true,
  requiredFields = defaultRequiredFields,
  ...props
}: Props) => {
  const intl = useIntl();
  const getError = getElementError(errors, hasSubmitted);
  return (
    <div className="space-y-6">
      <InputText
        value={values.first_name}
        onChange={handleChange}
        name="first_name"
        label={intl.formatMessage(messages.labelFirstName)}
        required={requiredFields.includes('first_name')}
        error={getError('first_name')}
        data-testid="guest-registration-first_name"
        autoComplete="given-name"
      />
      <InputText
        value={values.last_name}
        onChange={handleChange}
        name="last_name"
        label={intl.formatMessage(messages.labelLastName)}
        required={requiredFields.includes('last_name')}
        error={getError('last_name')}
        data-testid="guest-registration-last_name"
        autoComplete="family-name"
      />
      {includeEmail && (
        <InputText
          value={values.email}
          onChange={handleChange}
          name="email"
          label={intl.formatMessage(messages.labelEmail)}
          required={requiredFields.includes('email')}
          error={getError('email')}
          data-testid="guest-registration-email"
          autoComplete="email"
        />
      )}
      {includeEmailConfirmation && (
        <InputText
          value={values.email_confirmation}
          onChange={handleChange}
          name="email_confirmation"
          label={intl.formatMessage(messages.labelEmailConfirmation)}
          required={requiredFields.includes('email_confirmation')}
          error={getError('email_confirmation')}
          data-testid="guest-registration-email_confirmation"
          autoComplete="email"
        />
      )}
      <InputText
        value={values.phone}
        onChange={handleChange}
        name="phone"
        label={intl.formatMessage(messages.labelPhone)}
        required={requiredFields.includes('phone')}
        error={getError('phone')}
        autoComplete="tel"
        data-testid="guest-registration-phone"
      />
      {includePrivacyPolicy && (
        <Checkbox
          name="accepted_privacy_policy"
          checked={values.accepted_privacy_policy}
          onChange={handleChange}
          label={intl.formatMessage(messages.labelPrivacyPolicy, {
            a: (text: ReactNode) => (
              <a
                href="https://adventistgiving.helpscoutdocs.com/article/84-privacy-policy"
                target="_blank"
                className="underline"
                rel="noreferrer"
              >
                {text}
              </a>
            ),
          })}
          error={getError('accepted_privacy_policy')}
        />
      )}
    </div>
  );
};
