import {
  InputHTMLAttributes,
  ReactNode,
  forwardRef,
  useState,
  ChangeEventHandler,
} from 'react';
import { useIntl } from 'react-intl';
import debounce from 'lodash.debounce';
import ReactSelect, { ActionMeta, ClassNamesConfig } from 'react-select';
import AsyncSelect from 'react-select/async';
import { ChevronDown } from '../icons';
import InputTooltip, {
  InputTooltipPlacement,
  TooltipTextAlign,
} from '../input-tooltip/input-tooltip';
import { Text } from '@components/atoms/text/text';
import { FormError, getErrorMessage } from '@hooks/use-form-validation';

interface SelectOption {
  value: string;
  text: string;
  id?: string;
  className?: string;
}
type sizeSelect = 'small' | 'normal' | 'big';
interface Props extends InputHTMLAttributes<HTMLSelectElement> {
  options: SelectOption[];
  className?: string;
  selectClassName?: string;
  label?: string;
  elementSize?: sizeSelect;
  error?: FormError;
  defaultOption?: string;
  containerClassName?: string;
  tooltip?: ReactNode;
  tooltipPlacement?: InputTooltipPlacement;
  tooltipTextAlign?: TooltipTextAlign;
  tooltipAriaLabel?: string;
  filter?: boolean;
  isClearable?: boolean;
  loadMore?: (inputValue: string) => Promise<SelectOption[]>;
}

interface Style {
  box: string;
  text: string;
  textColor: string;
  chevron: string;
  reactSelectSize: string;
}

const styles: Record<sizeSelect, Style> = {
  small: {
    box: '!h-10',
    text: 'text-s p1 pl-2.5',
    textColor: '!text-nad-alps-night',
    chevron: 'top-4 right-2',
    reactSelectSize: 'text-s',
  },
  normal: {
    box: '!h-10',
    text: 'text-m p1 pl-2.5',
    textColor: '!text-nad-alps-night',
    chevron: 'top-4 right-2',
    reactSelectSize: 'text-m',
  },
  big: {
    box: '!h-10',
    text: 'text-m px-4 font-sans font-bold uppercase tracking-wider',
    textColor: '!text-nad-blue',
    chevron: 'top-4 right-4',
    reactSelectSize: 'text-m',
  },
};

const Select = forwardRef<HTMLSelectElement, Props>(
  (
    {
      className = '',
      elementSize = 'normal',
      defaultOption,
      error,
      options,
      selectClassName = '',
      label = '',
      required,
      containerClassName = '',
      tooltip,
      tooltipPlacement = 'responsive',
      tooltipTextAlign = 'left',
      tooltipAriaLabel,
      isClearable = true,
      filter = false,
      loadMore,
      onChange,
      ...props
    },
    ref
  ) => {
    if (loadMore) {
      filter = false;
    }
    const useReactSelect = filter || !!loadMore;
    const intl = useIntl();
    const [value, setValue] = useState<string>();
    const _onChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
      setValue(e.target.value);
      onChange?.(e);
    };
    if (defaultOption === undefined) {
      defaultOption = intl.formatMessage({
        id: 'p58F8z',
        description: 'Placeholder for a number of select fields.',
        defaultMessage: 'Please select an option',
      });
    }
    const id = props.id ? props.id : props.name ? props.name : undefined;
    props.id = id;
    const borderColor = error
      ? '!border-nad-scarlett'
      : '!border-nad-alps-night-2';
    const textColor = error
      ? '!text-nad-scarlett'
      : (value || props.value) !== undefined
        ? styles[elementSize].textColor
        : '!text-nad-alps-night-2';
    const reactSelectClasses: ClassNamesConfig = {
      control: (state) =>
        `${
          state.isFocused ? '' : ''
        } !rounded-none ${borderColor} ${textColor} ${
          styles[elementSize].reactSelectSize
        } ${styles[elementSize].box}`,
      input: () => `[&>*]:!text-nad-ming [&>*]:!ring-0`,
      option: (state) =>
        `${state.isFocused && !state.isSelected ? '!bg-nad-light-blue' : ''} ${
          state.isSelected ? '!bg-nad-blue' : ''
        } ${(state.data as any)?.className || ''}`,
      placeholder: () => `${textColor}`,
      indicatorSeparator: () => `hidden`,
      indicatorsContainer: () =>
        '[&>*:last-child]:!invisible [&>*]:!px-1 [&>*]:cursor-pointer [&>*]:hover:!text-nad-alps-night',
      singleValue: () => `${textColor}`,
    };
    const reactSelectOnChange = (newValue: any, meta: ActionMeta<any>) => {
      if (newValue?.value === '-search-for-more-') {
        return;
      }

      const event: any = {
        target: {
          name: props.name,
          value: newValue?.value || '',
          selectedOption: newValue,
          type: 'select',
        },
      };
      _onChange(event);
    };
    let reactSelectOptions = useReactSelect
      ? options.slice(0, 500).map((o) => ({
          label: o.text,
          value: o.value,
          id: o.id,
          className: o.className,
        }))
      : undefined;
    if (reactSelectOptions?.length === 500) {
      reactSelectOptions.push({
        label: 'Search for more results...',
        value: '-search-for-more-',
        id: '-search-for-more-',
        className: '',
      });
    }
    const reactSelectValue =
      reactSelectOptions?.find((o) => o.value === props.value) || '';
    const loadOptions = loadMore
      ? debounce(async (inputValue, callback) => {
          const options = (await loadMore(inputValue))
            .slice(0, 500)
            .map((o) => ({
              label: o.text,
              value: o.value,
              id: o.id,
              className: o.className,
            }));
          if (options.length === 500) {
            options.push({
              label: 'Search for more results...',
              value: '-search-for-more-',
              id: '-search-for-more-',
              className: '',
            });
          }
          callback(options);
          return options;
        }, 300)
      : undefined;
    return (
      <div className={`text-left relative ${containerClassName}`}>
        {label && (
          <label className="text-s" htmlFor={id}>
            <span className="text-nad-alps-night">{label}</span>
            {required && <span className="text-nad-scarlett text-s">*</span>}
          </label>
        )}
        {useReactSelect && (
          <div className="relative">
            {loadMore && (
              <AsyncSelect
                placeholder={defaultOption}
                value={reactSelectValue}
                classNames={reactSelectClasses}
                loadOptions={loadOptions}
                onChange={reactSelectOnChange}
                defaultOptions={reactSelectOptions}
                isClearable={isClearable}
              />
            )}
            {filter && (
              <ReactSelect
                placeholder={defaultOption}
                value={reactSelectValue}
                classNames={reactSelectClasses}
                onChange={reactSelectOnChange}
                options={reactSelectOptions}
                isClearable={isClearable}
              />
            )}

            <div
              className={`absolute pointer-events-none ${styles[elementSize].chevron}`}
            >
              <ChevronDown
                size="S"
                color={error ? 'nad-scarlett' : 'nad-blue'}
              />
            </div>
          </div>
        )}
        {!useReactSelect && (
          <div
            className={`${className} border-1 relative ${styles[elementSize].box}`}
          >
            <select
              ref={ref}
              className={`bg-none w-full h-full py-1 ${styles[elementSize].text} ${borderColor} ${textColor} ${selectClassName}`}
              onChange={_onChange}
              {...props}
            >
              {defaultOption && (
                <option key="option_default" value="">
                  {defaultOption}
                </option>
              )}
              {options.map((option, key) => (
                <option key={`option_${key}`} value={option.value}>
                  {option.text}
                </option>
              ))}
            </select>
            <div
              className={`absolute pointer-events-none ${styles[elementSize].chevron}`}
            >
              <ChevronDown size="S" color={error && 'nad-scarlett'} />
            </div>
          </div>
        )}
        {tooltip && (
          <InputTooltip
            style="default"
            placement={tooltipPlacement}
            textAlign={tooltipTextAlign}
            aria-label={tooltipAriaLabel}
          >
            {tooltip}
          </InputTooltip>
        )}
        {error && error.type && (
          <Text size="S" color="nad-scarlett">
            {getErrorMessage(intl, error, label)}
          </Text>
        )}
      </div>
    );
  }
);

Select.displayName = 'Select';

export default Select;
