import { defineMessages } from '@formatjs/intl';
import { useIntl } from 'react-intl';
import InputText from '../../atoms/input-text/input-text';
import { InputPassword } from '../input-password/input-password';
import { FormErrors, getElementError } from '@hooks/use-form-validation';

const messages = defineMessages({
  password: {
    id: 'erHafo',
    defaultMessage: 'Password',
    description: 'Label for sign up form',
  },
  passwordConfirmation: {
    id: 'wENeUO',
    defaultMessage: 'Password Confirmation',
    description: 'Label for sign up form',
  },
});

interface PasswordData {
  password: string;
  password_confirmation: string;
}

interface Props {
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  values: PasswordData;
  errors?: FormErrors<PasswordData>;
  hasSubmitted?: boolean;
}

export const CreatePassword = ({
  values,
  errors,
  hasSubmitted,
  handleChange,
}: Props) => {
  const intl = useIntl();
  const getError = getElementError(errors, hasSubmitted);
  return (
    <div className="sm:space-y-6 space-y-3.5">
      <InputPassword
        value={values.password}
        onChange={handleChange}
        label={intl.formatMessage(messages.password)}
        name="password"
        required
        error={getError('password')}
        data-testid="password-input"
        autoComplete="new-password"
      />
      <InputText
        type="password"
        value={values.password_confirmation}
        onChange={handleChange}
        label={intl.formatMessage(messages.passwordConfirmation)}
        name="password_confirmation"
        required
        error={getError('password_confirmation')}
        data-testid="password-confirmation-input"
        autoComplete="new-password"
      />
    </div>
  );
};
