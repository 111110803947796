import { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import InputText from '../../atoms/input-text/input-text';
import { FormErrors, getElementError } from '@hooks/use-form-validation';
import Select from '@components/atoms/select/select';
import { COUNTRIES } from 'assets/countries';
import { PROVINCES } from 'assets/provinces';
import { USA_STATES } from 'assets/states';

interface MailingAddressData {
  address?: string;
  address2?: string;
  country?: string;
  city?: string;
  state?: string;
  postal_code?: string;
}

interface Props {
  handleChange: (
    e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>
  ) => void;
  values: MailingAddressData;
  errors?: FormErrors<MailingAddressData>;
  hasSubmitted?: boolean;
}

const messages = defineMessages({
  labelAddress1: {
    id: 'IU7LVU',
    description: 'Form label for the first line of an address.',
    defaultMessage: 'Address Line 1',
  },
  labelAddress2: {
    id: 'JCP4Xv',
    description: 'Form label for the optional second line of an address.',
    defaultMessage: 'Address Line 2',
  },
  labelCountry: {
    id: '/e/wUT',
    description: 'Form label for the country portion of an address.',
    defaultMessage: 'Country',
  },
  labelCountryPlaceholder: {
    id: 'Qz2+r0',
    description:
      'Form placeholder for the country field when no option is selected.',
    defaultMessage: 'Please select a country first',
  },
  labelCity: {
    id: '4SK71J',
    description: 'Form label for the city portion of an address.',
    defaultMessage: 'City',
  },
  labelPostalOrZipCode: {
    id: 'HQ0LTp',
    description:
      'Form label for the zip/postal code. Note we are showing both "Zip" and "Postal Code" at the same time to accommodate multiple regions.',
    defaultMessage: 'Zip/Postal',
  },
  labelPostalCode: {
    id: 'h2dESb',
    description: 'Form label for the postal code.',
    defaultMessage: 'Postal Code',
  },
  labelZipCode: {
    id: 'Xmq6A2',
    description: 'Form label for the zip code.',
    defaultMessage: 'Zip Code',
  },
  labelStateOrProvince: {
    id: '2H0wnX',
    description:
      'Form label for the state/province portion of an address. This is shown when we the user has not selected a country.',
    defaultMessage: 'State/Province',
  },
  labelState: {
    id: 'UxzZAT',
    description:
      'Form label for the state portion of an address. This is shown when the country selected is not Canada.',
    defaultMessage: 'State',
  },
  labelStatePlaceholder: {
    id: 'wAgK/Z',
    description:
      'Form placeholder for the state field when no option is selected.',
    defaultMessage: 'Select',
  },
  labelProvince: {
    id: 'guF1jx',
    description:
      'Form label for the province portion of an address. This is shown when the country selected is Canada.',
    defaultMessage: 'Province',
  },
});

const countryOptions = COUNTRIES.map((c) => ({
  value: c.name,
  text: c.name,
}));

const canadaProvinceOptions = PROVINCES.map((c) => ({
  value: c.abbreviation,
  text: c.name,
}));

const usaStateOptions = USA_STATES.map((c) => ({
  value: c.abbreviation,
  text: c.name,
}));

export const MailingAddress = ({
  values,
  handleChange,
  errors,
  hasSubmitted,
  ...props
}: Props) => {
  const intl = useIntl();
  const getError = getElementError(errors, hasSubmitted);
  const handleCountryChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    if (value === 'United States' || value === 'USA' || value === 'Canada') {
      setUseSelectForState(true);
    } else {
      setUseSelectForState(false);
    }
    stateLabel = getStateLabel(value);
    handleChange(e);
  };
  const getStateLabel = (value: string | undefined) => {
    if (value === 'United States' || value === 'USA') {
      return intl.formatMessage(messages.labelState);
    } else if (value === 'Canada' || value === 'CA') {
      return intl.formatMessage(messages.labelProvince);
    }

    return intl.formatMessage(messages.labelStateOrProvince);
  };
  const getPostalCodeLabel = (value: string | undefined) => {
    if (value === 'United States' || value === 'USA') {
      return intl.formatMessage(messages.labelZipCode);
    } else if (value === 'Canada' || value === 'CA') {
      return intl.formatMessage(messages.labelPostalCode);
    }

    return intl.formatMessage(messages.labelPostalOrZipCode);
  };
  let stateLabel = getStateLabel(values.country);
  let postalCodeLabel = getPostalCodeLabel(values.country);
  const [useSelectForState, setUseSelectForState] = useState(true);
  return (
    <div className="sm:space-y-6 space-y-3.5">
      <InputText
        value={values.address}
        onChange={handleChange}
        name="address"
        label={intl.formatMessage(messages.labelAddress1)}
        required
        error={getError('address')}
        data-testid="guest-registration-address"
        autoComplete="address-line1"
      />
      <InputText
        value={values.address2}
        onChange={handleChange}
        name="address2"
        label={intl.formatMessage(messages.labelAddress2)}
        error={getError('address2')}
        data-testid="guest-registration-address2"
        autoComplete="address-line2"
      />
      <Select
        label={intl.formatMessage(messages.labelCountry)}
        name="country"
        defaultOption={intl.formatMessage(messages.labelCountryPlaceholder)}
        value={values.country}
        onChange={handleCountryChange}
        options={countryOptions}
        error={getError('country')}
        required
        data-testid="guest-registration-country"
      ></Select>
      {values.country && (
        <>
          <div className="flex flex-row space-x-4 m-0">
            <div className="flex-1">
              <InputText
                onChange={handleChange}
                name="city"
                label={intl.formatMessage(messages.labelCity)}
                value={values.city}
                required
                error={getError('city')}
                data-testid="guest-registration-city"
                autoComplete="address-level2"
              />
            </div>
            <div className="flex-1">
              {useSelectForState && (
                <Select
                  value={values.state}
                  onChange={handleChange}
                  name="state"
                  label={stateLabel}
                  error={getError('state')}
                  options={
                    !values.country
                      ? []
                      : values.country === 'Canada'
                        ? canadaProvinceOptions
                        : usaStateOptions
                  }
                  defaultOption={intl.formatMessage(
                    messages.labelStatePlaceholder
                  )}
                  required
                  data-testid="guest-registration-state"
                ></Select>
              )}
              {!useSelectForState && (
                <InputText
                  value={values.state}
                  onChange={handleChange}
                  name="state"
                  label={stateLabel}
                  disabled={!values.country}
                  required
                  autoComplete="address-level1"
                  data-testid="guest-registration-state"
                />
              )}
            </div>
          </div>
          <div className="w-1/2 pr-2">
            <InputText
              value={values.postal_code}
              onChange={handleChange}
              name="postal_code"
              label={postalCodeLabel}
              required
              maxLength={16}
              error={getError('postal_code')}
              data-testid="guest-registration-postal-code"
              autoComplete="postal-code"
            />
          </div>
        </>
      )}
    </div>
  );
};
