import React, { useEffect, useState } from 'react';
import { getCsrfToken, useSession } from 'next-auth/react';
import { useIntl } from 'react-intl';
import {
  FormErrors,
  getIntlErrorMessage,
  handleFormChange,
  setResponseErrorMessages,
  validateRequiredFields,
} from './use-form-validation';
import { useRecaptcha } from './use-recaptcha';
import { useCurrentUser } from './use-user';
import { fetchPost } from '@fetch/helpers';
import { VerifyEmailResponse } from '@models/http/verify-email-response';

interface VerifyEmailFormData {
  verification_code: string;
}

interface ValidationParams {
  requiredFields: (keyof VerifyEmailFormData)[];
  values: VerifyEmailFormData;
}

const validate = ({ requiredFields, values }: ValidationParams) => {
  let errors = validateRequiredFields(requiredFields, values);
  const hasErrors = Object.values(errors).some((e) => e && e.type);
  return { errors, hasErrors };
};

interface Params {
  onSuccess?: () => void;
}

const requiredFields: (keyof VerifyEmailFormData)[] = ['verification_code'];

const formData = {
  verification_code: '',
};

const useVerifyEmail = ({ onSuccess }: Params) => {
  const [values, setValues] = useState(formData);
  const [errors, setErrors] = useState<FormErrors<VerifyEmailFormData>>();
  const [hasErrors, setFormHasErrors] = useState(false);
  const [submitError, setSubmitError] = useState<string[]>();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const intl = useIntl();
  const { checkRecaptcha } = useRecaptcha({
    action: 'verifyEmailForm',
    setIsSubmitting,
    setSubmitError,
  });

  const { update: updateSession } = useSession();
  const { data: user, mutate: mutateUser } = useCurrentUser();

  useEffect(() => {
    const _errors = validate({ requiredFields, values });
    setErrors(_errors.errors);
    setFormHasErrors(_errors.hasErrors);
  }, [values]);

  const handleChange = handleFormChange({ values, setValues });

  const reset = () => {
    setValues(formData);
    setErrors(undefined);
    setFormHasErrors(false);
    setSubmitError(undefined);
    setHasSubmitted(false);
    setIsSubmitting(false);
  };

  const updateValues = (newValues: Partial<VerifyEmailFormData>) => {
    setValues({
      ...values,
      ...newValues,
    });
  };

  const handleSubmit = async (e?: React.SyntheticEvent) => {
    e?.preventDefault();
    /* istanbul ignore next */
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    setHasSubmitted(true);

    const token = await checkRecaptcha();
    if (!token) {
      return;
    }

    const csrfToken = await getCsrfToken();
    setSubmitError(undefined);
    const _errors = validate({ requiredFields, values });
    setErrors(_errors.errors);
    setFormHasErrors(_errors.hasErrors);
    if (_errors.hasErrors) {
      setSubmitError([getIntlErrorMessage('formValidationErrors', intl)]);
      setIsSubmitting(false);
      return;
    }

    const response = await fetchPost({
      url: `/api/v3/current_user/email_addresses/verify`,
      data: {
        ...values,
        recaptcha: token,
        csrfToken,
      },
    });
    if (response.ok) {
      const data: VerifyEmailResponse = await response.json();
      mutateUser();

      updateSession({ scope: data.scope });
      if (onSuccess) {
        onSuccess();
      }
      setTimeout(reset, 500);
      setIsSubmitting(false);
    } else {
      setResponseErrorMessages({ response, setErrors, setSubmitError, intl });
      setIsSubmitting(false);
    }
  };

  return {
    handleChange,
    updateValues,
    values,
    handleSubmit,
    errors,
    hasErrors,
    hasSubmitted,
    isSubmitting,
    submitError,
    reset,
  };
};

export default useVerifyEmail;
