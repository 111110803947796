import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { Button } from '@components/atoms/button/button';
import { sendEmailVerificationCode, useCurrentUser } from '@hooks/use-user';

import { getElementError } from '@hooks/use-form-validation';
import { Text } from '@components/atoms/text/text';
import FormErrorMessage from '@components/molecules/form-error-message/form-error-message';
import { Heading } from '@components/atoms/heading/heading';
import useVerifyEmail from '@hooks/use-verify-email-form';
import InputVerification from '@components/atoms/input-verification/input-verification';
import { linkBasicUnderline } from '@components/atoms/link/link';
import { TextUppercase } from '@components/atoms/text-uppercase/text-uppercase';

const messages = defineMessages({
  labelVerificationCode: {
    id: 'CLsyFq',
    defaultMessage: 'Enter verification code',
    description: 'Label for verification code, shown on verify email modal.',
  },
});

interface Props {
  newEmail?: string;
  showCancel?: boolean;
  onSuccess: () => void;
  onCancel?: () => void;
}

export const VerifyEmailForm = ({
  newEmail,
  showCancel = true,
  onSuccess,
  onCancel,
}: Props) => {
  const intl = useIntl();
  const {
    values,
    updateValues,
    handleSubmit,
    hasSubmitted,
    errors,
    hasErrors,
    submitError,
    isSubmitting,
    reset,
  } = useVerifyEmail({
    onSuccess,
  });
  const getError = getElementError(errors, hasSubmitted);
  const [emailResent, setEmailResent] = useState(false);
  const [emailResentSaved, setEmailResentSaved] = useState(false);
  const { data: user } = useCurrentUser();
  useEffect(() => {
    if (user && !newEmail) {
      sendEmailVerificationCode(user.email);
    }
  }, [user, newEmail]);
  return (
    <form onSubmit={handleSubmit} noValidate data-testid="verify-email-form">
      <Heading variant="h3" color="nad-blue" className="text-center">
        <FormattedMessage
          id="B6K0ig"
          defaultMessage="Verify Email"
          description="Modal title to verify email."
        />
      </Heading>
      <div className="container-xs mt-10 space-y-6">
        <Text size="XS" color="nad-alps-night">
          <FormattedMessage
            id="UV9kYL"
            defaultMessage="We have sent a verification email to {email}. Please enter the code found in the email. The code will expire in 15 minutes."
            description="Modal description for verifying email."
            values={{
              email: (newEmail || user?.email)!,
            }}
          />
        </Text>
        <InputVerification
          name="verification_code"
          value={values.verification_code}
          onChange={(value) => {
            updateValues({ ...values, verification_code: value });
          }}
          label={intl.formatMessage(messages.labelVerificationCode)}
          error={getError('verification_code')}
          required
        />
        <div className="w-full flex flex-col space-y-6 items-center">
          <FormErrorMessage errors={submitError} />
          <div className="flex flex-col items-center space-y-2">
            <Button
              isLoading={isSubmitting}
              disabled={
                isSubmitting ||
                (hasSubmitted && hasErrors) ||
                values.verification_code.length < 6
              }
              width="large"
              data-testid="verify-email-button-submit"
            >
              <FormattedMessage
                id="Oep6va"
                defaultMessage="Submit"
                description="Submit button"
              />
            </Button>
            {showCancel && onCancel && (
              <Button variant="link" type="button" onClick={() => onCancel()}>
                <FormattedMessage
                  id="Zxd2Dj"
                  defaultMessage="Cancel Verification"
                  description="Cancel email verification button"
                />
              </Button>
            )}
          </div>
        </div>
        <Text className="text-center" size="XS" color="nad-alps-night">
          <FormattedMessage
            id="uxR7Ll"
            defaultMessage="Didn’t receive the email? <a>Resend verification email</a>"
            description="CTA to resent email verification email."
            values={{
              a: (text) => (
                <button
                  className={linkBasicUnderline}
                  onClick={() => {
                    if (newEmail || user) {
                      setEmailResent(true);
                      sendEmailVerificationCode((newEmail || user?.email)!);
                      setEmailResentSaved(false);
                      reset();
                      setTimeout(() => {
                        setEmailResentSaved(true);
                      }, 500);
                    }
                  }}
                  type="button"
                >
                  {text}
                </button>
              ),
            }}
          />
        </Text>
        {emailResent && (
          <TextUppercase
            size="S"
            color="nad-ming"
            className={`text-center transition-opacity duration-200 ease-in ${
              emailResentSaved ? 'opacity-100' : 'opacity-0'
            }`}
          >
            <FormattedMessage
              id="LmoLsw"
              defaultMessage="Email Sent"
              description="Confirmation that the verifiation email was resent."
            />
          </TextUppercase>
        )}
      </div>
    </form>
  );
};
