import { useEffect, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Text } from '@components/atoms/text/text';
import InputText, {
  InputTextProps,
} from '@components/atoms/input-text/input-text';
import {
  checkPasswordStrength,
  MIN_PASSWORD_LENGTH,
} from '@hooks/use-form-validation';

export const passwordMessages = defineMessages({
  veryWeak: {
    id: '6qpv4h',
    defaultMessage: 'Very Weak',
    description: 'Password strength value.',
  },
  weak: {
    id: 'OpVGrD',
    defaultMessage: 'Weak',
    description: 'Password strength value.',
  },
  average: {
    id: '2ZvZL2',
    defaultMessage: 'Average',
    description: 'Password strength value.',
  },
  good: {
    id: 'VOQv0r',
    defaultMessage: 'Good',
    description: 'Password strength value.',
  },
  strong: {
    id: 'Xw3Osz',
    defaultMessage: 'Strong',
    description: 'Password strength value.',
  },
  passwordStrengthHelp: {
    id: '9jcSTI',
    defaultMessage: `For security purposes, please consider creating a password that:
    <ul>
    <li>Is at least 8 characters long</li>
    <li>Contains at least 3 of the following character types:
    <ul>
        <li>Uppercase letter (A – Z)</li>
        <li>Lowercase letter (a – z)</li>
        <li>Number (0 – 9)</li>
        <li>Non-alphanumeric character (+*!&)</li>
      </ul>
    </li>
    </ul>
    `,
  },
});

interface PassowrdStronger {
  [key: string]: {
    value: number;
    text: string;
    color: string;
  };
}

interface Props extends InputTextProps {
  value?: string;
}

export const InputPassword = ({ value, ...props }: Props) => {
  const intl = useIntl();
  const [passStronger, setPassStronger] = useState('veryWeak');

  const passwordStronger: PassowrdStronger = {
    veryWeak: {
      text: intl.formatMessage(passwordMessages.veryWeak),
      value: 0,
      color: 'bg-nad-light-blue',
    },
    weak: {
      text: intl.formatMessage(passwordMessages.weak),
      value: 1,
      color: 'bg-nad-scarlett',
    },
    avarage: {
      text: intl.formatMessage(passwordMessages.average),
      value: 2,
      color: 'bg-nad-yellow',
    },
    good: {
      text: intl.formatMessage(passwordMessages.good),
      value: 3,
      color: 'bg-nad-light-green',
    },
    strong: {
      text: intl.formatMessage(passwordMessages.strong),
      value: 4,
      color: 'bg-nad-green',
    },
  };
  useEffect(() => {
    if (!value) {
      return;
    }
    const lengthStrength = value.length >= MIN_PASSWORD_LENGTH ? 1 : 0;
    const strength = checkPasswordStrength(value) + lengthStrength;
    if (strength < 2) {
      setPassStronger('veryWeak');
    } else if (strength < 3) {
      setPassStronger('weak');
    } else {
      if (strength < 5 && lengthStrength) {
        setPassStronger('good');
      } else if (strength < 6 && lengthStrength) {
        setPassStronger('strong');
      } else {
        setPassStronger('avarage');
      }
    }
  }, [value]);

  return (
    <div>
      <InputText type="password" autoComplete="off" value={value} {...props} />
      {value && (
        <>
          <div className="flex justify-between space-x-2 my-2">
            {Array.from(Array(4).keys()).map((idx) => {
              if (idx < passwordStronger[passStronger].value) {
                return (
                  <div
                    key={`pass_space_${idx}`}
                    className={`flex-1 h-2 ${passwordStronger[passStronger].color}`}
                  ></div>
                );
              }
              return (
                <div
                  key={`pass_space_${idx}`}
                  className={`flex-1 h-2 bg-nad-light-blue`}
                ></div>
              );
            })}
          </div>
          <Text
            color="nad-alps-night-2"
            size="2XS"
            data-testid="input-password-strength"
          >
            <FormattedMessage
              id="PX8UHF"
              defaultMessage="Password Strength: {value}"
              description="Message shown to users when they enter a password."
              values={{
                value: passwordStronger[passStronger].text,
              }}
            />
          </Text>
        </>
      )}
    </div>
  );
};
