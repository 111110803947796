/* istanbul ignore file */
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ReactNode } from 'react';
import { envRecaptchaSiteKey, envRequireCaptcha } from 'helpers/public-env';

interface Props {
  children: ReactNode;
}
const RecaptchaWrapper = ({ children }: Props) => {
  if (!envRequireCaptcha()) {
    return <>{children}</>;
  }
  return (
    <GoogleReCaptchaProvider reCaptchaKey={`${envRecaptchaSiteKey()}`}>
      {children}
    </GoogleReCaptchaProvider>
  );
};
export default RecaptchaWrapper;
